<script setup lang="ts">
</script>
<template>
  <div
    class="aboutus"
    id="about-us"
  >
    <div class="aboutus-content">
      <h2 class="center-title">О КОМПАНИИ</h2>
      <div class="aboutus-content-plates">
        <div class="aboutus-content-plates-plate">
          <img
            class="aboutus-content-plates-plate-image"
            src="@/assets/images/svg/work-time.svg"
            alt="">
          <div class="aboutus-content-plates-plate-text">
            <p class="aboutus-content-plates-plate-text-title">>3</p>
            <p class="aboutus-content-plates-plate-text-description">Лет <br>работы</p>
          </div>
        </div>
        <div class="aboutus-content-plates-plate ad">
          <img
            class="aboutus-content-plates-plate-image"
            src="@/assets/images/svg/ad.svg"
            alt="">
          <div class="aboutus-content-plates-plate-text ad">
            <p class="aboutus-content-plates-plate-text-title">570+</p>
            <p class="aboutus-content-plates-plate-text-description">Рекламных <br>кампаний</p>
          </div>
        </div>
        <div class="aboutus-content-plates-plate">
          <img
            class="aboutus-content-plates-plate-image lids"
            src="@/assets/images/svg/lids.svg"
            alt="">
          <div class="aboutus-content-plates-plate-text lids">
            <p class="aboutus-content-plates-plate-text-title">2,5м</p>
            <p class="aboutus-content-plates-plate-text-description">Лидов</p>
          </div>
        </div>
      </div>
      <div class="aboutus-content-info">
        <p class="aboutus-content-info-title">JIGSAW CORP - стратегия, опыт, <br>стремление</p>
        <p class="aboutus-content-info-description">
          Наша группа рекламных компаний использует передовые <br>
          рекламные инструменты социального трафика. <br><br>
          Разрабатываем digital стратегии, повышаем узнаваемость <br>
          бренда, настраиваем прибыльные рекламные кампании. <br><br>
          Превращаем вашу бизнес-идею в сверкающий бриллиант <br>
          успешного бренда.
        </p>
        <div class="aboutus-content-info-icon">
          <img
            class="aboutus-content-info-icon-image"
            src="@/assets/images/svg/info.svg"
            alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.aboutus-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: get-vw-pc(1619px);
  margin-left: auto;
  margin-right: auto;
}
.aboutus {
  &-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: get-vw-pc(263px);
    &-plates {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: get-vw(70px);
      width: 100%;
      &-plate {
        display: flex;
        flex-direction: row;
        align-items: center;
        &.ad {
          margin-left: get-vw-pc(-70px);
        }
        &-image {
          width: get-vw(330px);
          margin-right: get-vw(-10px);
          &.lids {
            margin-top: get-vw-pc(-25px);
            width: get-vw(250px);
          }
        }
        &-text {
          display: flex;
          flex-direction: column;
          color: #2D2D2D;
          margin-left: get-vw-pc(-20px);
          &.ad {
            margin-top: get-vw(-2px);
          }
          &.lids {
            margin-left: 0;
            margin-top: get-vw(-4px);
          }
          &-title {
            font-size: get-vw(45px);
            font-weight: 700;
          }
          &-description {
            font-size: get-vw(17px);
            font-weight: 400;
          }
        }
      }
    }
    &-info {
      padding: get-vw-pc(85px);
      width: get-vw-pc(706px);
      height: get-vw-pc(358px);
      border: get-vw-pc(9px) solid #2E6ADB;
      border-radius: get-vw-pc(67px);
      margin-top: get-vw-pc(145px);
      position: relative;
      &-icon {
        width: get-vw-pc(91.58px);
        height: get-vw-pc(92.49px);
        border-radius: get-vw(10px);
        position: absolute;
        top: get-vw-pc(-46px);
        left: get-vw-pc(-46px);
        background: #000000;
        overflow: hidden;
        &-image {
          width: get-vw-pc(98px);
          height: get-vw-pc(98px);
          transform: scale(1.4);
        }
      }
      &-title {
        font-size: get-vw(21px);
        font-weight: 700;
      }
      &-description {
        margin-top: get-vw-pc(41px);
        font-size: get-vw(18px);
        line-height: get-vw-pc(33px);
      }
    }
  }
}
</style>
